<template>
  <div>
    <!--MODAL ADD/EDIT-->
    <b-modal
      id="commercials"
      ref="commercials"
      size="lg"
      :title="getTitle()"
      :ok-disabled="$v.$invalid || loading"
      :ok-title="modalName === 'add' ? $t('table.add') : $t('table.edit')"
      :cancel-title="$t('table.cancel')"
      :hide-footer="!canEdit && !canUpdateAccompanyingSheet"
      :busy="isModalBusy"
      @ok.prevent="showModalAddCommercialsConfirm"
    >
      <SpinnerLoader v-if="loading" :loading="loading === true ? 'loading' : 'success'" />
      <form v-else @submit.stop.prevent="checkIfValidAddThenEnter">
        <input type="submit" value="Submit" class="hidden-submit" />
        <b-form-group :label="$t('table.writeName')" label-for="input-1-commercial">
          <b-form-input
            id="input-1-commercial"
            v-model.trim="modal.name"
            type="text"
            :placeholder="$t('table.writeName')"
            required
            autofocus
            :disabled="!canEdit || isOneDay"
          ></b-form-input>
        </b-form-group>

        <div class="d-flex align-items-center gap-3">
          <b-form-group :label="$t('table.version')" style="width: 49%">
            <MultiSelect
              v-model="modal.commVerType"
              :options="commercialVersionTypes.data"
              :placeholder="$t('table.version')"
              label="name"
              track-by="id"
              :disabled="!canEdit || isOneDay"
            ></MultiSelect>
          </b-form-group>
          <b-form-group v-if="modalName === 'edit'" label="ID" label-for="input-10-commercial" style="width: 25%; margin-right: 0.5rem">
            <b-form-input id="input-10-commercial" :value="modal.id" type="number" placeholder="ID" required disabled></b-form-input>
          </b-form-group>
          <b-form-checkbox
            id="checkbox-visible"
            v-model="modal.is_visible"
            name="checkbox-visible"
            class="custom-control-inline"
            style="margin-top: 0.9rem"
            :disabled="!canEdit || isOneDay || !canUpdateIsVisible"
          >
            {{ $t('table.isVisible') }}
          </b-form-checkbox>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <b-form-group :label="$t('table.selectCommercialType')" style="width: 49%">
            <MultiSelect
              v-model="modal.commercial"
              :options="commercialTypeWithoutAll"
              :placeholder="$t('table.selectCommercialType')"
              label="name"
              track-by="id"
              :disabled="!canEdit || isOneDay || isFromMediaPlan"
            ></MultiSelect>
          </b-form-group>

          <b-form-group :label="$t('table.duration')" label-for="input-3-dur-commercial" style="width: 49%">
            <b-form-input
              id="input-3-dur-commercial"
              v-model.number="modal.duration"
              type="number"
              :step="1"
              :placeholder="$t('table.duration')"
              min="0"
              max="240"
              required
              :disabled="!canEdit || isOneDay"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <b-form-group
            id="input-group-4"
            :label="$t('table.dataValidUntil') + ' (' + $t('table.optional') + ')'"
            style="width: 49%"
            label-for="datepicker-legal-before-at"
          >
            <datepicker-wrapper
              id="datepicker-legal-before-at"
              v-model="modal.legal_before_at"
              :reset-button="modalName === 'add'"
              :disabled="!canEdit || isAMA"
            />
          </b-form-group>
          <b-form-group :label="`${$t('table.externalId')} (${$t('table.optional')})`" label-for="input-3-2-external" style="width: 49%">
            <b-form-input
              id="input-3-2-external"
              v-model.number="modal.externalId"
              type="number"
              :step="1"
              :placeholder="$t('table.externalId')"
              :disabled="!canEdit || !canEditExternalId"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <b-form-group :label="$t('table.selectAdvertiser')" style="width: 49%">
            <MultiSelect
              v-model="modal.advertiser"
              :options="advertisersList"
              :placeholder="$t('table.selectAdvertiser')"
              label="name"
              track-by="id"
              :disabled="!canEdit || isOneDay || isFromMediaPlan"
            ></MultiSelect>
          </b-form-group>
          <b-form-group :label="$t('table.brand')" style="width: 49%">
            <MultiSelect
              v-model="modal.brand"
              :options="brands || []"
              :placeholder="$t('table.brand')"
              label="name"
              track-by="id"
              :disabled="!canEdit || isOneDay || isFromMediaPlan"
            ></MultiSelect>
          </b-form-group>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <b-form-checkbox v-model="modal.isCoBrand" name="check-button-cobrand" switch :disabled="!canEdit || (modalName === 'edit' && !canUpdateCoBrand)" class="mb-3" style="width: 49%">
            {{ $t('table.co_brand') }} ({{ $t('table.optional') }})
          </b-form-checkbox>
          <b-form-group v-if="modal.isCoBrand" :label="$t('table.surchargeForCoBrand') + ' (%)'" style="width: 49%">
            <b-input-group>
              <b-form-input id="input-co-branding-discount" v-model.trim="modal.co_branding_discount" :placeholder="$t('table.surchargeForCoBrand')" :disabled="!canEdit || (modalName === 'edit' && !canUpdateCoBrandingDiscount)"></b-form-input>
              <b-input-group-append>
                <b-button variant="outline-danger" :disabled="!canEdit || (modalName === 'edit' && !canUpdateCoBrandingDiscount)" @click="modal.co_branding_discount = ''">{{ $t('table.delete') }}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>

        <div v-if="modal.isCoBrand" class="d-flex align-items-center justify-content-between">
          <b-form-group :label="$t('table.selectAdvertiser')" style="width: 49%">
            <MultiSelect
              v-model="modal.co_advertiser"
              :options="advertisersList"
              :placeholder="$t('table.selectAdvertiser')"
              label="name"
              track-by="id"
              :disabled="!canEdit"
            ></MultiSelect>
          </b-form-group>
          <b-form-group :label="$t('table.co_brand')" style="width: 49%">
            <MultiSelect
              v-model="modal.co_brand"
              :options="brandsListOther || []"
              :placeholder="$t('table.brand')"
              label="name"
              track-by="id"
              :disabled="!canEdit"
            ></MultiSelect>
          </b-form-group>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <b-form-group :label="`URL (${$t('table.optional')})`" label-for="input-8-url" style="width: 49%">
            <b-form-input id="input-8-url" v-model.trim="modal.url" type="text" placeholder="URL" :disabled="!canEdit"></b-form-input>
          </b-form-group>
          <b-form-group :label="$t('table.status')" style="width: 49%">
            <select v-model="modal.status" class="custom-select" :disabled="!canEdit || !canEditOnChannels">
              <option value=""></option>
              <option value="0">{{ $t('table.new') }}</option>
              <option value="1">{{ $t('table.onChannel') }}</option>
            </select>
          </b-form-group>
        </div>

        <div v-if="modal.status == 1 && $checkPermissions('scope.commercials_on_channels')" class="d-flex align-items-center gap-3">
          <b-form-group :label="$t('table.selectChannels')" style="width: 100%">
            <MultiSelect
              v-model="modal.channels"
              :options="channels.data || []"
              :multiple="true"
              :close-on-select="false"
              :placeholder="$t('table.selectChannels')"
              label="name"
              track-by="id"
              :disabled="!canEdit || !canEditOnChannels"
            ></MultiSelect>
          </b-form-group>
        </div>

        <div v-if="this.$checkPermissions('scope.commercials_approve')" class="d-flex align-items-center gap-3">
          <b-form-group :label="$t('table.approveComment')" style="width: 100%">
            <b-form-textarea
              id="input-approve-comment"
              v-model="modal.approve_comment"
              :placeholder="$t('table.approveComment')"
              rows="3"
              max-rows="6"
              :disabled="!(canEdit && canUpdateApprove)"
            ></b-form-textarea>
            <b-form-checkbox
              id="checkbox-is-approved"
              v-model="modal.is_approved"
              name="checkbox-is-approved"
              class="custom-control-inline"
              style="margin-top: 0.9rem"
              :disabled="!(canEdit && canUpdateApprove)"
            >
              {{ $t('table.approve') }}
            </b-form-checkbox>
          </b-form-group>
        </div>

        <div v-if="canUpdateAccompanyingSheet">
          <div class="text-center">
            <hr>
            <strong>{{ $t('table.accompanyingSheet') }}</strong>
          </div>
          <div class="d-flex align-items-center gap-3">
            <b-form-group :label="$t('table.asRegId')" style="width: 100%">
              <b-form-input id="input-as-reg-id" v-model.trim="modal.as_reg_id" type="text" :placeholder="$t('table.asRegId')"></b-form-input>
            </b-form-group>
          </div>
          <div class="d-flex align-items-center gap-3">
            <b-form-group :label="$t('table.asApproveComment')" style="width: 100%">
              <b-form-textarea
                id="input-as-approve-comment"
                v-model="modal.as_approve_comment"
                :placeholder="$t('table.asApproveComment')"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
              <b-form-checkbox
                id="checkbox-as-is-approved"
                v-model="modal.as_is_approved"
                name="checkbox-as-is-approved"
                class="custom-control-inline"
                style="margin-top: 0.9rem"
              >
                {{ $t('table.asApprove') }}
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>

    <!-- CONFIRM COMMERCIAL ADD -->
    <b-modal
      id="add-commercials-confirm"
      ref="add-commercials-confirm"
      size="sm"
      :title="modalName === 'add' ? $t('table.addCommercials') : $t('table.editCommercials')"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      :busy="isModalBusy"
      @ok.prevent="addCommercialsConfirm"
    >
      <p class="my-2">
        {{ modalName === 'add' ? $t('table.confirmAddCommercials') : $t('table.confirmEditCommercials') }}
        <span class="text-danger">{{ modal.name }}</span>
        ?
      </p>
    </b-modal>
    <!-- CONFIRM COMMERCIAL ADD -->

    <!-- CONFIRM COMMERCIAL EDIT -->
    <ModalEditCommercialConfirm
      :is-open-modal="isEditConfirmModalOpen"
      :acs-count="modal.acsCount"
      @modalEditCommercialConfirmOK="addCommercials($event)"
      @modalEditCommercialConfirmCancel="isEditConfirmModalOpen = false || hideModalAddCommercialsConfirm()"
    />
    <!-- CONFIRM COMMERCIAL EDIT -->

    <!--MODAL DELETE-->
    <b-modal
      id="delete-commercials-confirm"
      ref="delete-commercials-confirm"
      size="sm"
      :title="$t('table.deleteCommercials')"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      :busy="isModalBusy"
      @ok.prevent="deleteCommercials"
    >
      <p class="my-2">
        {{ $t('table.confirmDeleteCommercials') }} <span class="text-danger">{{ commercial ? commercial.name : '' }}</span>
        ?
      </p>
    </b-modal>
    <!--MODAL DELETE-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { integer, decimal, required, minValue, maxValue, sameAs, not } from 'vuelidate/lib/validators';
import MultiSelect from '@/components/MultiSelect';
import errorsHandler from '@/utils/errorsHandler';
import SpinnerLoader from '@/components/SpinnerLoader';
import DatepickerWrapper from '@/components/DatepickerWrapper';
import ModalEditCommercialConfirm from '@/components/Commercials/ModalEditCommercialConfirm';

export default {
  name: 'ModalCommercial',
  components: { MultiSelect, SpinnerLoader, DatepickerWrapper, ModalEditCommercialConfirm },
  props: {
    isOpenModal: {
      type: String,
      default: undefined,
    },
    commercial: {
      type: Object,
      default: undefined,
    },
    modalName: {
      type: String,
      default: 'add',
    },
    brandId: {
      type: Number,
      default: undefined,
    },
    isOneDay: {
      type: Boolean,
    },
    isFromMediaPlan: {
      type: Boolean,
    },
    commercialTypeId: {
      type: Number,
      default: undefined,
    },
    canEdit: {
      type: Boolean,
    },
    projectAdvertiserId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      modal: {
        id: '',
        externalId: '',
        name: '',
        commVerType: '',
        commercial: '',
        duration: '',
        advertiser: '',
        co_advertiser: '',
        brand: '',
        co_brand: '',
        co_branding_discount: '',
        legal_before_at: '',
        url: '',
        status: 0,
        is_visible: true,
        channels: [],
        isCoBrand: false,
        acsCount: 0,
      },
      loading: false,
      isModalBusy: false,
      isEditConfirmModalOpen: false,
    };
  },
  validations: {
    modal: {
      name: { required },
      commVerType: { required },
      commercial: { required },
      duration: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(240),
      },
      co_brand: {
        notSameBrand: not(sameAs('brand')),
      },
      co_branding_discount: {
        decimal,
        minValue: minValue(0),
        maxValue: maxValue(100),
      },
      brand: { required },
      //legal_before_at: {required},
      status: { required },
      acsCount: {
        integer,
        minValue: minValue(0),
      },
    },
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
      isLocale: 'isLocale',
      commercials: 'getCommercialsList',
      commercialsStatus: 'getCommercialsStatus',
      modalEditCommercials: 'getModalEditCommercials',
      advertisersList: 'getAdvertisersList',
      commercialVersionTypes: 'getCommercialVersionTypes',
      commercialTypeWithoutAll: 'getCommercialTypeWithoutAll',
      //brandsList: 'getBrandsList',
      brandsListOther: 'getBrandsListOther',
      brands: 'getBrands',
      channels: 'getChannels',
      isAMA: 'getIsCurrentUserAgencyManager',
    }),
    canEditExternalId: function () {
      return this.$checkPermissions('commercial.update_external_id');
    },
    canEditOnChannels: function () {
      return this.$checkPermissions('commercial.update_on_channels');
    },
    canUpdateCoBrand() {
      return this.$checkPermissions('commercial.update_co_brand');
    },
    canUpdateCoBrandingDiscount() {
      return this.$checkPermissions('commercial.update_co_branding_discount');
    },
    canUpdateIsVisible() {
      return this.$checkPermissions('commercial.update_is_visible');
    },
    canUpdateAccompanyingSheet() {
      return this.$checkPermissions('commercial.update_accompanying_sheet');
    },
    canUpdateApprove() {
      return this.$checkPermissions('commercial.update_approve');
    }
  },
  watch: {
    isOpenModal() {
      this.showModal();
    },

    async 'modal.advertiser'() {
      if (this.modal.advertiser && !this.loading) {
        await this.$store.dispatch('GET_BRANDS_FIRST', {
          'filter[advertiser_id]': this.modal.advertiser.id,
          per_page: 1000,
        });
        this.modal.brand = '';
      }
    },
    async 'modal.co_advertiser'() {
      if (this.modal.co_advertiser && !this.loading) {
        await this.$store.dispatch('GET_BRANDS_OTHER', {
          'filter[advertiser_id]': this.modal.co_advertiser.id,
          per_page: 1000,
        });
        this.modal.co_brand = '';
      }
    },
  },

  created() {},

  mounted() {},

  methods: {
    getTitle() {
      if (this.modalName === 'add') return this.$i18n.t('table.addNewCommercials');
      else if (this.modalName === 'edit' && !this.canEdit) return this.modal.name;
      else return this.$i18n.t('table.editCommercials');
    },

    clearData() {
      this.modal.id = '';
      this.modal.externalId = '';
      this.modal.commVerType = '';
      this.modal.name = '';
      this.modal.commercial = '';
      this.modal.duration = '';
      this.modal.advertiser = '';
      this.modal.brand = '';
      this.modal.co_brand = '';
      this.modal.co_branding_discount = '';
      this.modal.legal_before_at = '';
      this.modal.url = '';
      this.modal.status = 0;
      this.modal.is_visible = true;
      this.modal.co_advertiser = '';
      this.modal.channels = [];
      this.modal.isCoBrand = false;
      this.modal.is_approved = null;
      this.modal.approve_comment = null;
      this.modal.as_name_for_playlist = null;
      this.modal.as_reg_id = null;
      this.modal.as_is_approved = null;
      this.modal.as_approve_comment = null;
      this.isEditConfirmModalOpen = false;
      this.modal.acsCount = 0;
    },

    async showModal() {
      if (!this.isOpenModal) {
        return;
      }
      this.$store.dispatch('CLEAR_BRANDS_LIST');
      await this.$store.dispatch('GET_ADVERTISERS', { per_page: 2000 });
      if (this.modalName !== 'delete') {
        this.loading = true;
        this.$refs['commercials'].show();
        this.clearData();
        await Promise.all([
          this.$store.dispatch('GET_COMM_VER_TYPES', { per_page: 1000 }),
          this.$store.dispatch('GET_COMMERCIAL_TYPE'),
          !(this.channels && this.channels.data.length > 0) ? this.$store.dispatch('GET_CHANNELS', { per_page: 1000 }) : undefined,
        ]);

        // Load data if opened from Inspect Commercial modal
        if (this.projectAdvertiserId && this.brandId) {
          this.modal.advertiser = this.advertisersList.find((el) => el.id === this.projectAdvertiserId);
          await this.$store.dispatch('GET_BRANDS_FIRST', {
            'filter[advertiser_id]': this.projectAdvertiserId,
            per_page: 1000,
          });
          this.modal.brand = this.brands.find((el) => el.id === this.brandId);
          this.modal.commercial = this.commercialTypeWithoutAll.find((type) => type.id === this.commercialTypeId);
        }

        if (this.modalName === 'edit') {
          if (!this.advertisersList || this.advertisersList.length < 1) await this.$store.dispatch('GET_ADVERTISERS', { per_page: 2000 });
          const id = this.commercial.id || this.commercial.commercial_id;
          await this.$store.dispatch('GET_COMMERCIALS_ID', { id, data: { include: 'brand,coBrand,channels', append: 'settings_count' } });
          if (this.modalEditCommercials) {
            this.modal.id = this.modalEditCommercials.id;
            this.modal.commVerType = this.commercialVersionTypes.data.find((el) => el.id === this.modalEditCommercials.commercial_version_type_id);
            this.modal.name = this.modalEditCommercials.name;
            this.modal.commercial = this.commercialTypeWithoutAll.find((type) => type.id === this.modalEditCommercials.commercial_type_id);
            this.modal.duration = this.modalEditCommercials.duration;
            this.modal.advertiser = this.advertisersList.find((el) => el.id === this.modalEditCommercials.brand.advertiser_id);
            this.modal.acsCount = this.modalEditCommercials?.settings_count?.in_open_months || 0;
            await this.$store.dispatch('GET_BRANDS_FIRST', {
              'filter[advertiser_id]': this.modalEditCommercials.brand.advertiser_id,
              per_page: 1000,
            });
            this.modal.brand = this.brands.find((el) => el.id === this.modalEditCommercials.brand_id);
            if (this.modalEditCommercials.co_brand_id) {
              this.modal.isCoBrand = true;
              this.modal.co_advertiser = this.advertisersList.find((el) => el.id === this.modalEditCommercials.co_brand.advertiser_id);
              await this.$store.dispatch('GET_BRANDS_OTHER', {
                'filter[advertiser_id]': this.modalEditCommercials.co_brand.advertiser_id,
                per_page: 1000,
              });
              this.modal.co_brand = this.brandsListOther.find((el) => el.id === this.modalEditCommercials.co_brand_id);
              this.modal.co_branding_discount = this.modalEditCommercials.co_branding_discount || '';
            }
            this.modal.legal_before_at = this.modalEditCommercials.legal_before_at;
            this.modal.url = this.modalEditCommercials.url;
            this.modal.status = this.modalEditCommercials.status;
            this.modalEditCommercials.channels.forEach((channel) => {
              const ch = this.channels.data.find((ch) => ch.id === channel.id);
              if (ch) this.modal.channels.push(ch);
            });
            this.modal.is_visible = this.modalEditCommercials.is_visible;
            this.modal.externalId = this.modalEditCommercials.external_id;
            this.modal.is_approved = this.modalEditCommercials.is_approved;
            this.modal.approve_comment = this.modalEditCommercials.approve_comment;
            this.modal.as_name_for_playlist = this.modalEditCommercials.as_name_for_playlist;
            this.modal.as_reg_id = this.modalEditCommercials.as_reg_id;
            this.modal.as_is_approved = this.modalEditCommercials.as_is_approved;
            this.modal.as_approve_comment = this.modalEditCommercials.as_approve_comment;
          }
        }
        this.loading = false;
      } else {
        await this.showModalDeleteCommercials();
      }
      this.$emit('isOpenModal', null);
    },
    hideModalAddCommercials() {
      this.$refs['commercials'].hide();
    },
    showModalAddCommercialsConfirm() {
      this.$refs['add-commercials-confirm'].show();
    },
    hideModalAddCommercialsConfirm() {
      this.$refs['add-commercials-confirm'].hide();
    },
    async addCommercialsConfirm() {
      if (this.modalName === 'add' || !this.modal?.acsCount) {
        this.addCommercials();
      } else {
        this.isEditConfirmModalOpen = true;
      }
    },
    async addCommercials(updateMP = false) {
      this.isEditConfirmModalOpen = false;
      this.isModalBusy = true;
      let formData = {};
      formData = {
        commercial_version_type_id: this.modal.commVerType.id,
        commercial_type_id: this.modal.commercial.id,
        brand_id: this.modal.brand.id,
        co_brand_id: this.modal.isCoBrand && this.modal.co_brand?.id || null,
        co_branding_discount: this.modal.isCoBrand && this.modal.co_branding_discount || null,
        name: this.modal.name,
        duration: this.modal.duration,
        legal_before_at: this.modal.legal_before_at && !this.isAMA ? this.modal.legal_before_at : undefined,
        url: this.modal.url,
        status: +this.modal.status,
        is_visible: this.modal.is_visible,
        external_id: this.canEditExternalId ? this.modal.externalId : undefined,
        channels: this.modal.status == 1 ? (this.canEdit ? this.modal.channels : this.modalEditCommercials.channels).map((e) => e.id) : [],
        is_approved: this.modal.is_approved,
        approve_comment: this.modal.approve_comment == '' ? null : this.modal.approve_comment,
        as_name_for_playlist: this.modal.as_name_for_playlist === '' ? null : this.modal.as_name_for_playlist,
        as_reg_id: this.modal.as_reg_id === '' ? null : this.modal.as_reg_id,
        as_is_approved: this.modal.as_is_approved,
        as_approve_comment: this.modal.as_approve_comment === '' ? null : this.modal.as_approve_comment,
      };
      if (this.modalName === 'add') {
        await this.$store.dispatch('POST_COMMERCIALS', {
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.addCommercials'),
              text: this.modal.name,
            });
            this.$emit('update');
            this.clearData();
            this.hideModalAddCommercials();
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
      } else {
        formData.update_mediaplans_in_open_months = updateMP || false;
        const commercialId = this.modal.id;
        await this.$store.dispatch('PUT_COMMERCIALS', {
          commercialId,
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.editCommercials'),
              text: this.modal.name,
            });
            this.$emit('update');
            this.clearData();
            this.hideModalAddCommercials();
            this.$store.commit('clearModalEditCommercials');
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
      }
      this.hideModalAddCommercialsConfirm();
      this.isModalBusy = false;
    },

    // modal delete
    async showModalDeleteCommercials() {
      this.$refs['delete-commercials-confirm'].show();
    },
    hideModalDeleteCommercialsConfirm() {
      this.$refs['delete-commercials-confirm'].hide();
    },
    async deleteCommercials() {
      this.isModalBusy = true;
      const formData = this.commercial.id;
      await this.$store.dispatch('DELETE_COMMERCIALS_ID', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.deleteCommercials'),
            text: this.commercial.name,
          });
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.$emit('update');
      this.hideModalDeleteCommercialsConfirm();
      this.isModalBusy = false;
    },

    checkIfValidAddThenEnter() {
      if (!this.$v.$invalid && !this.isModalBusy) this.showModalAddCommercialsConfirm();
    },
  },
};
</script>
