<template>
  <div>
    <b-modal id="modal-edit-commercial-confirm" ref="modal-edit-commercial-confirm" scrollable>
      <template #modal-header>
        <h4>{{ $t('table.editCommercials') }}</h4>
        <b-icon font-scale="1.75" icon="x" style="cursor: pointer" @click="hideModalCancel"></b-icon>
      </template>

      <template #default>
        {{ $t('table.needToRecalculateMediaplans', { acsCount }) }}
      </template>

      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="hideModalConfirmCalc">
            {{ $t('table.editCommercialWithMediaplanRecalculation') }}
          </b-button>
        </div>
        <div class="w-100">
          <b-button class="float-right" @click="hideModalConfirm">
            {{ $t('table.editCommercial') }}
          </b-button>
        </div>
        <div class="w-100">
          <b-button variant="outline-danger" class="float-right" @click="hideModalCancel">
            {{ $t('table.cancel') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalInspectCommercial',
  props: {
    isOpenModal: {
      required: false,
      type: Boolean,
    },
    acsCount: {
      required: true,
      type: Number,
    },
  },
  watch: {
    isOpenModal(val) {
      if (val) {
        this.$refs['modal-edit-commercial-confirm'].show();
      } else {
        this.$refs['modal-edit-commercial-confirm'].hide();
      }
    },
  },
  methods: {
    hideModalCancel() {
      this.$emit('modalEditCommercialConfirmCancel');
    },
    hideModalConfirm() {
      this.$emit('modalEditCommercialConfirmOK', false);
    },
    hideModalConfirmCalc() {
      this.$emit('modalEditCommercialConfirmOK', true);
    }
  },
}
</script>
